@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Inter-Black';
  src: url('../public/fonts/Inter-Black.ttf');
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('../public/fonts/Inter-Bold.ttf');
}

@font-face {
  font-family: 'Inter-ExtraBold';
  src: url('../public/fonts/Inter-ExtraBold.ttf');
}

@font-face {
  font-family: 'Inter-ExtraLight';
  src: url('../public/fonts/Inter-ExtraLight.ttf');
}

@font-face {
  font-family: 'Inter-Light';
  src: url('../public/fonts/Inter-Light.ttf');
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('../public/fonts/Inter-Medium.ttf');
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('../public/fonts/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: url('../public/fonts/Inter-SemiBold.ttf');
}

@font-face {
  font-family: 'Inter-Thin';
  src: url('../public/fonts/Inter-Thin.ttf');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}